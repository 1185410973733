.products {
  font-family: "Merriweather", serif;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  background-color: white;
  padding: 150px 100px 100px 200px;
  width: 100%;
  height: 100%;
  background-color: #efeddc;
  @media (max-width: 768px) {
    padding: 150px 0px 50px 0px;
  }
  .menuTitle {
    font-size: 36px;
    margin-top: 9%;
    margin-bottom: 3%;
    @media (max-width: 768px) {
      margin-top: 30%;
    }
  }
  .product-container {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    align-items: center;
    justify-content: center;
    .list-item {
      width: calc(33% - 40px);
      position: relative;
      margin: 20px;
      .item {
        cursor: pointer;
        border-radius: 30px;
        height: 300px;
        background-size: 145%;
        background-position: center;
        transition: background-size 2s ease-out 100ms;
        box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.3);
        &:hover {
          background-size: 170%; /* Adjust zoom factor as needed */
          transition: background-size 0.5s ease-out 50ms;
          .list-name,
          .list-desc {
            background: transparent;
            color: black;
          }
        }
        .list-name {
          position: absolute;
          bottom: 0px;
          width: -webkit-fill-available;
          display: flex;
          justify-content: center;
          font-size: 26px;
          color: white;
          //background: rgba(0, 0, 0, 0.5);
          background: rgba(88, 63, 60, 0.7);
          height: 300px;
          display: flex;
          align-items: center;
          border-radius: 30px;
          @media (max-width: 768px) {
            height: 200px;
          }
        }
        .list-desc {
          position: absolute;
          bottom: 32px;
          font-size: 16px;
          color: white;
          display: flex;
          width: -webkit-fill-available;
          justify-content: center;
        }
        &.kurbanlık {
          background-image: url("../assets/bayram1.JPG");
        }
        &.dana {
          background-image: url("../assets/dananew.jpg");
        }
        &.keci {
          background-image: url("../assets/kecinew.jpg");
        }
        &.sut {
          background-image: url("../assets/sutnew.jpeg");
        }
        &.egg {
          background-image: url("../assets/yumurtanew.jpg");
        }
        &.horoz {
          background-image: url("../assets/horoznew.jpg");
        }
        &.koc {
          background-image: url("../assets/kocnew.jpg");
        }
        @media (max-width: 768px) {
          height: 200px;
        }
      }
      @media (max-width: 768px) {
        width: calc(50% - 20px);
        margin: 10px;
      }
    }
  }
}
