.about-us {
  width: 100%;
  height: 100%;
  display: flex;
  background-color: #efeddc;
  padding: 0px 100px 0px 0px;
  color: rgb(88, 63, 60);
  @media (max-width: 768px) {
    flex-direction: column;
    padding: 0;
  }
  .about-us-background {
    background: url("../assets/about.jpg");
    width: 50%;
    background-size: cover;
    @media (max-width: 768px) {
      width: 100%;
      height: 60vh;
    }
  }
  .about-us-content {
    font-family: "Raleway", sans-serif;
    width: 50%;
    padding: 150px 32px;
    border-left: 15px solid rgb(29 54 19);
    .about-section {
      margin-bottom: 20px;
      .about-title {
        font-family: "Merriweather", serif;
        font-size: 28px;
        margin-bottom: 10px;
      }
    }
    @media (max-width: 768px) {
      padding: 32px;
      width: 100%;
    }
  }
}
