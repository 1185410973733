.product-detail-page {
  font-family: "Merriweather", serif;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  background-color: white;
  width: 100%;
  height: 100%;
  background-color: #efeddc;
  @media (max-width: 768px) {
    flex-direction: column;
    padding: 0;
  }
  .product-slider {
    width: calc(50% - 40px);
    position: relative;
    left: -20px;
    border-right: 15px solid rgb(29 54 19);
    background: #1d3613;
    @media (max-width: 768px) {
      width: 100%;
      left: 0;
      border: none;
    }
    .slick-slider {
      .slick-slide {
        img {
          width: 100%;
          height: calc(100vh - 243px);
          object-fit: cover;
        }
        Video {
          width: 100%;
          height: calc(100vh - 243px);
          object-fit: cover;
        }
      }
      .slick-next {
        right: 10px;
      }
      .slick-prev {
        left: 10px;
        z-index: 999;
      }
    }
  }

  .product-detail-content {
    width: 50%;
    padding: 150px 100px 150px 32px;
    @media (max-width: 768px) {
      width: 100%;
      padding: 50px 32px;
      border-left: 0px solid rgb(29 54 19);
    }
    .section-title {
      font-family: "Merriweather", serif;
      font-size: 30px;
      width: 100%;
      display: flex;
      justify-content: center;
      color: #583f3c;
    }
    .section-description {
      font-family: "Merriweather", serif;
      margin: 20px 0px;
      text-align: center;
    }
  }
}
