.contact-page {
  font-family: "Raleway", sans-serif;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  background-color: white;
  padding: 150px 20px 46px 20px;
  width: 100%;
  height: 100%;
  background-color: #efeddc;
  @media (max-width: 768px) {
    padding: 150px 0px 50px 0px;
  }
  .contact {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-between;
    @media (max-width: 768px) {
      flex-direction: column;
    }
    a {
      color: #583f3c;
    }
    .info {
      text-align: center;
      width: 50%;
      box-sizing: border-box;
      @media (max-width: 768px) {
        width: 100%;
        margin-bottom: 32px;
      }
      .phoneicon {
        margin-right: 1%;
        color: black;
      }
      .envelopeicon {
        margin-right: 1%;
      }
      .instagramicon {
        margin-right: 1%;
      }
      .section-title {
        font-family: "Merriweather", serif;
        font-size: 30px;
        width: 100%;
        display: flex;
        justify-content: center;
        color: #583f3c;
        padding: 50px;
      }
      .info-item {
        font-size: 20px;
        margin-bottom: 16px;
        color: #583f3c;
      }
    }
    .pam {
      width: 50%;
      height: 500px;
      @media (max-width: 768px) {
        width: 100%;
      }
      .map {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 30px;
        border: none;
        box-shadow: 0px 4px 6px -4px rgba(16, 24, 40, 0.1),
          0px 10px 15px -3px rgba(16, 24, 40, 0.1);
      }
    }
  }
}
