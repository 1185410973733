.footer {
  margin: 0;
  width: 100%;
  display: flex;
  //background-color: rgb(88, 63, 60);
  background-color: #1d3613;
  align-items: normal;
  justify-content: space-between;
  color: rgb(142, 142, 142);
  padding: 40px;
  @media (max-width: 768px) {
    text-align: center;
    flex-direction: column;
    place-items: center;
    overflow-x: hidden;
    height: fit-content;
  }
  .footer-logo-container {
    width: 25%;
    .footer-logo {
      background-color: rgba(148, 148, 148, 0.537);
      border-radius: 15px;
      width: calc(100% - 32px);
    }
    @media (max-width: 768px) {
      width: 100%;
      margin-bottom: 16px;
    }
  }

  .footer-title {
    color: white;
    font-size: 20px;
    span {
      margin-left: 10px;
    }
  }
  .menu-container {
    text-decoration: none;
    color: rgb(201, 204, 208);
    display: flex;
    flex-direction: column;
    align-items: baseline;
    width: 25%;
    .nav__link {
      color: white;
      margin-top: 16px;
      li {
        margin-top: 1.5%;
        list-style: none;
        margin: 0;
        text-decoration: none;
        color: rgb(142, 142, 142);
      }
      a {
        color: rgb(142, 142, 142);
      }
    }
    @media (max-width: 768px) {
      margin-bottom: 16px;
      width: 100%;
      align-items: center;
    }
  }
  .ulasim {
    width: 25%;
    display: grid;
    place-items: center;
    color: rgb(201, 204, 208);
    justify-content: space-between;
    .ulasim-icon {
      margin-right: 10px;
    }
    .instalink {
      color: rgb(201, 204, 208); /* Link rengi */
      text-decoration: underline; /* Altını çizme */
    }
    @media (max-width: 768px) {
      text-align: center;
      margin-bottom: 16px;
      width: 100%;
      justify-content: center;
    }
  }

  .konum {
    color: rgb(201, 204, 208);
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;
    width: 25%;
    .address {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      margin-top: 16px;
      @media (max-width: 768px) {
        align-items: center;
        margin-bottom: 16px;
      }
    }
    @media (max-width: 1030px) {
      margin: 0;
      width: 100%;
      text-align: center;
    }
    @media (max-width: 768px) {
      width: 100%;
      text-align: center;
      align-items: center;
    }
  }
}
