*,
*::after,
*::before {
  margin: 0;
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
  body {
    font-size: 1.6rem;
    li {
      list-style: none;
      margin-right: 30px;
    }
    a {
      text-decoration: none;
    }
    .nav {
      position: fixed;
      top: 0;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      transition: 0.6s;
      padding: 16px 200px;
      z-index: 9999;
      &.sticky {
        padding: 5px 100px;
        background-color: rgb(247 247 247);
        box-shadow: 0px 4px 6px -4px rgba(16, 24, 40, 0.1),
          0px 10px 15px -3px rgba(16, 24, 40, 0.1);
        ul,
        li,
        a {
          color: #000;
        }
        img {
          height: 60px;
          width: 250px;
        }
        .gradient {
          height: calc(100vh - 73px);
        }
        @media screen and (max-width: 906px) {
          padding: 10px;
        }
      }
      @media screen and (max-width: 906px) {
        padding: 10px;
      }
      &__brand {
        position: relative;
        text-decoration: none;
        text-transform: uppercase;
        transition: 0.6s;
        img {
          width: 300px;
          border-radius: 15px;
        }
      }

      ul li {
        position: relative;
        list-style: none;

        a {
          position: relative;
          margin: 0 15px;
          text-decoration: none;
          color: #000;
          letter-spacing: 2px;
          font-weight: 500px;
          transition: 0.6;
        }
      }

      &__menu {
        display: flex;
        align-items: center;
        justify-content: space-around;
      }

      &__toggler {
        display: none;

        div {
          width: 2.5rem;
          height: 0.2rem;
          margin: 0.4rem;
          background: rgb(88, 63, 60);
          transition: 0.3s ease-in;
        }
      }

      @media screen and (max-width: 906px) {
        &__toggler {
          display: block;
          cursor: pointer;
        }

        &__menu {
          position: fixed;
          top: 7vh;
          right: 0;
          height: 93vh;
          width: 50vw;
          background-color: rgba(248, 189, 111);
          flex-direction: column;
          transform: translateX(100%);
          transition: 0.5s ease-in;

          &__brand img {
            width: 200px;
          }
        }
      }

      &__active {
        transform: translateX(0%);
      }

      /* Toggle Icon Animation */
      .toggle {
        .line1 {
          transform: rotate(-45deg) translate(-4px, 5px);
        }
        .line2 {
          opacity: 0;
        }
        .line3 {
          transform: rotate(45deg) translate(-4px, -5px);
        }
      }
    }
  }
}
