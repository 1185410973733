@keyframes slide {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}

.close-button {
  right: 0;
  position: absolute; /* Butonu mutlak konuma getir */
  background: transparent;
  border: none;
  color: rgb(0, 0, 0);
  font-size: 45px;
  cursor: pointer;
  padding: 0;
  z-index: 999; /* Popup'ın üzerinde olmasını sağlamak için */
  &:focus {
    outline: none; // Odaklandığında çerçeve stilini kaldırır
  }
  @media screen and (max-width: 768px) {
    font-size: 30px;
  }
}
.popup-overlay {
  background: rgba(0, 0, 0, 0.6);
}
.popup-content {
  margin: 0;
  background: transparent;
  padding: 0px;
  border: 0px solid #d7d7d7;
}
.popup-container {
  padding: 0;
  margin: 0;
  border: none;
  position: fixed;
  background-color: transparent;
  outline: none; /* Odaklandığında kenarlık göstermez */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 6%;
  background-color: rgb(255, 255, 255);
  padding: 2%;

  @media screen and (max-width: 768px) {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%; // Telefon boyutuna göre genişliği ayarla
    max-width: 400px; // Maksimum genişlik belirle
    object-fit: contain;
    img {
      border-radius: 6%;
      width: 100%;
      object-fit: contain;
    }
  }

  .popup-image {
    max-width: 100%; // Resmi popup boyutuna sığdırır
    max-height: 100%;
    display: block; // Resmi blok element olarak görüntüler
    margin: 0 auto; // Resmi yatay ortalar
    background-color: transparent;
    overflow-x: hidden;
    border-radius: 6%;
  }
  .popup-image img {
    display: flex;
    max-width: 70vh; /* Resmi maksimum genişlikte tut */
    max-height: 100vh; /* Resmi maksimum dikey yükseklikte tut, ekranın yüzde 80'i kadar */
    object-fit: contain;
    background-color: transparent;
    padding-bottom: 0;
    margin-bottom: 0;
  }

  overflow-y: auto; // Yalnızca dikey scrollbar'ı gösterir
}

.home-page {
  width: 100%;
  height: fit-content;
  overflow-x: hidden;

  .home {
    width: 100%;
    height: fit-content;
    display: flex;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url("../assets/farm.jpg");
    flex-direction: column;
    position: relative;
    overflow: hidden;
    @media (max-width: 768px) {
      flex-direction: column; /* Sıralamayı dikey yap */
      align-items: center;
      height: 100vh;
    }
    .kurbanlık {
      width: 40%;
      height: 40%;
      @media (max-width: 768px) {
        width: 60%;
        height: 60%;
      }
      @media (max-width: 500px) {
        width: 90%;
        height: 90%;
        margin-bottom: 15%;
      }
      img {
        object-fit: contain;
        height: 100%;
        width: 100%;
        @media (max-width: 500px) {
          width: 90%;
          height: 90%;
        }
      }
    }
    .sliding-text-container {
      color: white;
      height: 75px;
      //background: #1d3613;
      background-color: #125410;
      width: 100%;
      position: absolute;
      bottom: 0;
      z-index: 99;
      display: flex;
      align-items: center;
      .sliding-text {
        width: 100%;
        font-size: 32px;
        animation: slide 10s linear infinite;
        @media (max-width: 768px) {
          font-size: 27px;
        }
      }
    }
    .neon {
      width: 100%;
      height: 100vh;

      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      align-items: center;
      z-index: 999;
      color: #efeddc;

      .home-title {
        font-family: "Merriweather", serif;
        text-align: center;
        font-size: 3em;
        font-weight: 500;
        color: #efeddc;
        letter-spacing: 5px;
        cursor: pointer;
        transition: 0.6s;
        &:hover {
          scale: 1.2;
          transition: 0.6s;
        }
      }

      .home-content {
        align-items: center;
        text-align: center;
        margin-top: 50px;
        .home-description {
          font-family: "Raleway", sans-serif;
          font-size: 18px;
        }
        .wp-container {
          display: flex;
          justify-content: center;
          text-align: center;
          align-items: center;
          margin-top: 32px;
          .wp-button {
            background: none;
            border: none;
            font: inherit;
            cursor: pointer;
            display: flex;
            align-items: center;
            text-align: center;
            place-items: center;
            width: 100%;
            .btn-icon {
              align-items: center;
              background-color: transparent;
              color: green;
              font-size: 40px;
              width: min-content;
              cursor: pointer;
              margin-right: 15px;
            }
            .wp-label {
              font-family: "Raleway", sans-serif;
              font-size: 18px;
              color: #efeddc;
            }
          }
        }
      }
    }
  }
  .hizmetlerimiz-container {
    //background-color: #2c2323;
    //background: linear-gradient(to top, #efeddc 85%, #ab9d7c 100%);
    background-color: #efeddc;
    padding: 0px 100px 100px 200px;
    .section-title {
      font-family: "Merriweather", serif;
      font-size: 30px;
      width: 100%;
      display: flex;
      justify-content: center;
      color: #583f3c;
      padding: 50px;
    }
    .hizmetlerimiz-list {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      align-items: center;
      justify-content: center;
      .list-item {
        width: calc(33% - 40px);
        position: relative;
        margin: 20px;
        .item {
          cursor: pointer;
          border-radius: 30px;
          height: 300px;
          background-size: 145%;
          background-position: center;
          transition: background-size 2s ease-out 100ms;
          box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.3);
          &:hover {
            background-size: 170%; /* Adjust zoom factor as needed */
            transition: background-size 0.5s ease-out 50ms;
            .list-name {
              background: transparent;
              color: black;
            }
          }
          .list-name {
            font-family: "Raleway", sans-serif;
            position: absolute;
            bottom: 0px;
            width: -webkit-fill-available;
            display: flex;
            justify-content: center;
            font-size: 32px;
            color: white;
            //background: rgba(0, 0, 0, 0.5);
            background: rgba(88, 63, 60, 0.7);
            height: 300px;
            display: flex;
            align-items: center;
            border-radius: 30px;
            @media (max-width: 768px) {
              height: 200px;
            }
          }
          &.kurbanlık {
            background-image: url("../assets/bayram1.JPG");
          }
          &.dana {
            background-image: url("../assets/dananew.jpg");
          }
          &.keci {
            background-image: url("../assets/kecinew.jpg");
          }
          &.sut {
            background-image: url("../assets/sutnew.jpeg");
          }
          &.egg {
            background-image: url("../assets/yumurtanew.jpg");
          }
          &.horoz {
            background-image: url("../assets/horoznew.jpg");
          }
          &.koc {
            background-image: url("../assets/kocnew.jpg");
          }
          @media (max-width: 768px) {
            height: 200px;
          }
        }
        @media (max-width: 768px) {
          width: calc(50% - 20px);
          margin: 10px;
        }
      }
    }
    @media (max-width: 768px) {
      padding: 0px 20px 40px 20px;
    }
  }
}
